// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-dashboard-a-index-tsx": () => import("./../../../src/pages/dashboard-a/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-a-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monitor-template-index-tsx": () => import("./../../../src/pages/monitor-template/index.tsx" /* webpackChunkName: "component---src-pages-monitor-template-index-tsx" */),
  "component---src-templates-example-tsx": () => import("./../../../src/templates/example.tsx" /* webpackChunkName: "component---src-templates-example-tsx" */)
}

